<template>
  <section :class="`clients section ${getCurrentLangs.lang}`">
    <div class="clients__top">
      <Lottie
        :options="defaultOptions"
        class="clients__media desc"
        id="clients-animation"
        @play="startPlay"
        @animCreated="handleAnimation"
      />
      <div class="clients__top--content">
        <h2 class="section__title clients__title iosTitle type1">
          <span class="text-wrapper">
            <span class="letters">
              {{ `${$t('mainPage.clients.title[0]') + '&nbsp;'}` }}
            </span>
          </span>
          <span class="text-wrapper">
            <span class="letters">
              {{ $t('mainPage.clients.title[1]') }}
            </span>
          </span>
        </h2>
        <h4 class="section__subtitle clients__subtitle grow">{{ $t('mainPage.clients.subtitle') }}</h4>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[0]') }}</p>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[1]') }}</p>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[2]') }}</p>
      </div>
    </div>
    <ul v-if="getUserTheme === 'light-theme'" class="clients__list grow">

      <li class="clients__item clients__item1">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/abbot_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/abbot_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item2">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/arterium_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/arterium_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item3">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Astra_color.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Astra_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item4">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/bayer_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/bayer_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item5">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/biocodex_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/biocodex_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item6">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/boehringer_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/boehringer_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item7">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/danone_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/danone_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item8">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/farmak_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/farmak_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item9">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/gsk_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/gsk_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item10">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/ipsen_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/ipsen_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item11">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/kusum_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/kusum_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item12">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/medtronic_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/medtronic_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item13">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/microkhim_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/microkhim_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item14">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/MSD_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/MSD_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item15">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/nestle_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/nestle_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item16">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/nobel_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/nobel_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item17">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/novonordisk_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/novonordisk_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item18">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/olainfarm_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/olainfarm_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item19">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/opella_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/opella_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item20">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/organon_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/organon_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item21">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Pfizer_сolor.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Pfizer_сolor.png" alt="">-->
      </li>

      <li class="clients__item clients__item22">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Pharmasco_сolor.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Pharmasco_сolor.png" alt="">-->
      </li>

      <li class="clients__item clients__item23">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Reckitt_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Reckitt_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item24">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Recordati_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Recordati_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item25">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sandoz_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/sandoz_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item26">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sanofi_color.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/sanofi_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item27">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Servier_color.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Servier_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item28">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/takeda_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/takeda_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item29">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/teva_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/teva_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item30">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Unipharm_colour.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Unipharm_colour.png" alt="">-->
      </li>
    </ul>

    <ul v-if="getUserTheme === 'dark-theme'" class="clients__list grow">

      <li class="clients__item clients__item1">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/abbot_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/abbot_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item2">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/arterium_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/arterium_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item3">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Astra_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Astra_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item4">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/bayer_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/bayer_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item5">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/biocodex_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/biocodex_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item6">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/boehringer_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/boehringer_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item7">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/danone_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/danone_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item8">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/farmak_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/farmak_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item9">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/gsk_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/gsk_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item10">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/ipsen_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/ipsen_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item11">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/kusum_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/kusum_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item12">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/medtronic_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/medtronic_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item13">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/microkhim_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/microkhim_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item14">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/MSD_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/MSD_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item15">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/nestle_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/nestle_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item16">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/nobel_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/nobel_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item17">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/novonordisk_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/novonordisk_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item18">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/olainfarm_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/olainfarm_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item19">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/opella_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/opella_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item20">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/organon_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/organon_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item21">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Pfizer_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Pfizer_сolor.png" alt="">-->
      </li>

      <li class="clients__item clients__item22">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Pharmasco_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Pharmasco_сolor.png" alt="">-->
      </li>

      <li class="clients__item clients__item23">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Reckitt_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Reckitt_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item24">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Recordati_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Recordati_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item25">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sandoz_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/sandoz_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item26">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sanofi_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/sanofi_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item27">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Servier_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Servier_color.png" alt="">-->
      </li>

      <li class="clients__item clients__item28">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/takeda_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/takeda_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item29">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/teva_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/teva_colour.png" alt="">-->
      </li>

      <li class="clients__item clients__item30">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Unipharm_white.png" alt="">
        <!--        <img class="clients__item&#45;&#45;img-colour" src="@/assets/img/main-page/clients/Unipharm_colour.png" alt="">-->
      </li>
    </ul>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/clients.json';
import getElementVisible from '@/helper';

export default {
  name: 'Clients',
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs',
      'getUserTheme'
    ]),
  },
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    play() {
      this.anim.play();
    },
    stop() {
      this.anim.stop();
    },
    startPlay() {
      setTimeout(() => {
        if (getElementVisible('clients-animation')) {
          this.play();
        }
      }, 0);
    },
  },
  mounted() {
    document.addEventListener('scroll', this.startPlay);
  },
};
</script>

<style lang="scss" scoped>
.clients {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 405px;

    &--content {
      width: 50%;
    }
  }

  &__title {
    min-width: 535px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__media {
    &.desc {
      max-width: 500px;
    }
  }

  &__list {
    display: grid;
    // grid-template-columns: repeat(6, 189px);
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    padding: 0 390px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #92A1AE;
    border-top: 2px solid #92A1AE;
    height: 116px;
    position: relative;
    transition: all .3s ease;

    img {
      max-width: 53%;
      object-fit: contain;
      transition: opacity .3s ease;
    }

    .clients__item--img-colour {
      opacity: 0;
      position: absolute;
    }


    // @media screen and (min-width: 1023px) {
    //&:hover {
    //
    //  .clients__item--img-grey {
    //    opacity: 0;
    //  }
    //
    //  .clients__item--img-colour {
    //    opacity: 1;
    //  }
    //}
  }

  &__item6,
  &__item12,
  &__item18,
  &__item24,
  &__item30 {
    border-right: 2px solid #92A1AE;
  }

  &__item25,
  &__item26,
  &__item27,
  &__item28,
  &__item29,
  &__item30 {
    border-bottom: 2px solid #92A1AE;
  }

  //@for $i from 13 through 18 {
  //  .clients__item:nth-of-type(#{$i}) {
  //    border-bottom: 2px solid #92A1AE;
  //  }
  //}
}

@media screen and (max-width: 1600px) {
  .clients {
    &__top {
      padding-left: 172px;
      padding-right: 163px
    }

    &__list {
      padding-left: 163px;
      padding-right: 163px
    }
  }
}

@media screen and (max-width: 1440px) {
  .clients {
    &__list {
      margin-top: 28px;
    }

    &__item {
      height: 97px;

      img {
        max-width: 70%;
      }
    }

    &__list {
      padding-left: 150px;
      padding-right: 150px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .clients {
    &__title {
      min-width: unset;
    }

    &__top {
      padding: 0;

      &--content {
        margin-right: 4%;
        min-width: 320px;
      }
    }

    &__media {
      width: 40%;
    }

    &__list {
      margin-top: 16px;
      grid-template-columns: repeat(6, 118px);
    }

    &__item {
      padding: 0 8px;
      height: 72px;

      img {
        max-width: 80%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .clients {
    &__title {
      .text-wrapper {
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .clients {
    padding: 0 44px;

    &__list {
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
    }

    &__item {
      border-width: 1px;

      //&:nth-of-type(6),
      //&:nth-of-type(12),
      //&:nth-of-type(18) {
      //  border-right: 1px solid #92A1AE;
      //}
      //
      //&:nth-of-type(1) {
      //  .clients__item--img-colour {
      //    max-width: 46%;
      //  }
      //}
      //
      //&:nth-of-type(2) {
      //  .clients__item--img-colour {
      //    max-width: 46%;
      //  }
      //}
      //
      //&:nth-of-type(3) {
      //  .clients__item--img-colour {
      //    max-width: 51.8%;
      //  }
      //}
      //
      //&:nth-of-type(4) {
      //  .clients__item--img-colour {
      //    max-width: 69%;
      //  }
      //}
      //
      //&:nth-of-type(5) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(6) {
      //  .clients__item--img-colour {
      //    max-width: 45.59%;
      //  }
      //}
      //
      //&:nth-of-type(7) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(8) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(9) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(10) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(11) {
      //  .clients__item--img-colour {
      //    max-width: 45.7%;
      //  }
      //}
      //
      //&:nth-of-type(12) {
      //  .clients__item--img-colour {
      //    max-width: 68.8%;
      //  }
      //}
      //
      //&:nth-of-type(13) {
      //  .clients__item--img-colour {
      //    max-width: 51.73%;
      //  }
      //}
      //
      //&:nth-of-type(14) {
      //  .clients__item--img-colour {
      //    max-width: 60.35%;
      //  }
      //}
      //
      //&:nth-of-type(15) {
      //  .clients__item--img-colour {
      //    max-width: 34.5%;
      //  }
      //}
      //
      //&:nth-of-type(16) {
      //  .clients__item--img-colour {
      //    max-width: 56.04%;
      //  }
      //}
      //
      //&:nth-of-type(17) {
      //  .clients__item--img-colour {
      //    max-width: 69%;
      //  }
      //}
      //
      //&:nth-of-type(18) {
      //  .clients__item--img-colour {
      //    max-width: 44.7%;
      //  }
      //}
      //
      //&:nth-of-type(13),
      //&:nth-of-type(14),
      //&:nth-of-type(15),
      //&:nth-of-type(16),
      //&:nth-of-type(17),
      //&:nth-of-type(18) {
      //  border-bottom: 1px solid #92A1AE;
      //}
    }

    //@for $i from 13 through 18 {
    //  .clients__item:nth-of-type(#{$i}) {
    //    border-bottom: 1px solid #92A1AE;
    //  }
    //}
  }
}

@media screen and (max-width: 705px) {
  .clients {
    &__media {
      &.desc {
        max-width: 70%;
      }
    }

    &__title {
      max-width: 50%;
    }

    &__top {
      flex-direction: column;

      &--content {
        width: 100%;
        margin-right: 0;
        min-width: unset;
      }
    }

    &__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      height: 64px;

      img {
        max-width: 50%;
      }

      //&:nth-of-type(6),
      //&:nth-of-type(7) {
      //  img {
      //    max-width: 44%;
      //  }
      //
      //  .clients__item--img-colour {
      //    max-width: 37.64%;
      //  }
      //}

      //&:nth-of-type(3),
      //&:nth-of-type(9),
      //&:nth-of-type(15) {
      //  border-right: 1px solid #92A1AE;
      //}
    }

    &__item3,
    &__item9,
    &__item15,
    &__item21,
    &__item27 {
      border-right: 1px solid #92A1AE;
    }

    &__item28,
    &__item29,
    &__item30 {
      border-top: none;
    }

    //@for $i from 12 through 15 {
    //  .clients__item:nth-of-type(#{$i}) {
    //    border-bottom: none;
    //  }
    //}
  }
}

@media screen and (max-width: 660px) {
  .clients {
    padding: 0 20px;
  }
}

@media screen and (max-width: 525px) {
  .clients {
    &__item {

      img {
        max-width: 70%;
      }

    }
  }
}

@media screen and (max-width: 425px) {
  .clients {
    &__title {
      max-width: 70%;
    }

    &__item {

      img {
        max-width: 100%;
      }

    }

    &__subtitle {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 360px) {
  .clients {

    &__item {

      img {
        max-width: 100%;
      }

    }

    &__title {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 340px) {
  .clients {
    &__list {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}
</style>
